h1,
h2,
h3,
h4,
h5,
h6,
p {
  padding: 0;
  margin: 0;
}
textarea:focus,
input:focus,
select:focus {
  outline: none;
}
button {
  background: none !important;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
  cursor: pointer;
}

body {
  margin: 0;
  padding: 0;
  font-size: 10px;
  background-color: #ffe01b;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#roster-page {
  background-color: #ffe01b;
  width: 100vw;
  min-height: 100vh;
  padding-bottom: 50px;
}
#logo {
  color: inherit;
  width: 90vw;
  margin: 0 auto;
  text-align: right;
  font-size: 1.2rem;
  margin-top: 30px;
  margin-bottom: 10px;
}
#logo a {
  font-size: 1.4rem;
  font-weight: 600;
  margin-left: 3px;
}

#roster-page.embed {
  padding: 0;
  min-height: auto;
  width: 100%;
  background-color: #fff;
}

#roster__container {
  width: 90vw;
  background-color: #fff;
  border-radius: 8px;
  margin: 0 auto;
  padding: 0 50px;
  padding-top: 15px;
  padding-bottom: 40px;
}

#roster__container.embed {
  width: 100%;
  padding: 0;
  padding-bottom: 40px;
}

#roster__header {
  padding-top: 25px;
}
.roster__group {
}
.roster__group--flex {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.roster__label {
  text-align: right;
  padding-right: 15px;
}
.roster__group h1 {
  font-size: 28px;
  margin-bottom: 35px;
}
#search {
  position: relative;
}
#search img {
  position: absolute;
  width: 15px;
  top: 5px;
}
#search input {
  border: none;
  border-bottom: 1px solid #a3a7aa;
  width: 20vw;
  min-width: 100px;
  padding-left: 20px;
}
#sort {
  color: #a3a7aa;
}
#sort select {
  margin-left: 5px;
  color: #888;
  background: transparent;
  border-color: transparent;
  height: 30px;
}

#loading {
  width: 100%;
  text-align: center;
  font-size: 30px;
  font-weight: 700;
  margin-top: 23px;
}
/* MEMBER */
#rosters__table th,
#rosters__table td {
  padding: 15px;
}
.rosters__header-row {
  background-color: #f8f9fb;
}
.refresh-followers {
  margin-left: 5px;
}
.refresh-followers img {
  position: relative;
  top: -1px;
  width: 15px;
}
.member {
  font-size: 14px;
}
.member__profile-image img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
.member__handle {
}
.member__full_name {
}
.member__count {
}
.member__email {
}
.member__media img {
  width: 85px;
  height: auto;
  margin: 0 5px;
}
.member__actions {
  text-align: right;
}
.member__actions a {
  padding-left: 10px;
}

/* HELPERS */

.p-0 {
  padding: 0 !important;
}
.text-right {
  text-align: right;
}
.hover-opacity:hover {
  opacity: 0.7;
}
